import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { AuthService } from "./services/auth.service";
import { environment } from "../../environments/environment";
import { catchError, switchMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.api.adminApiBaseUrl)) {
      // Don't try to send token to login endpoint.
      return next.handle(req);
    }
    return this.handleWithAccessToken(req, next).pipe(
      catchError((firstError) => {
        if (this.isTokenExpiredError(firstError)) {
          console.log("Access token is expired");
          return this.authService.performTokenRefresh().pipe(
            switchMap(() => this.handleWithAccessToken(req, next)),
            catchError((secondError) =>
              this.handleNotAuthenticatedError(secondError)
            )
          );
        }
        return this.handleNotAuthenticatedError(firstError);
      })
    );
  }

  private handleWithAccessToken(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getEncodedAccessToken();
    if (accessToken) {
      const clonedRequest = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + accessToken),
      });
      return next.handle(clonedRequest);
    } else {
      return next.handle(req);
    }
  }

  private isTokenExpiredError(err: HttpErrorResponse): boolean {
    if (err.status === 401 && err.error) {
      const body = err.error;
      return (
        body.error &&
        body.error === "invalid_token" &&
        typeof body.error_description === "string" &&
        (body.error_description as string).startsWith("Access token expired")
      );
    }
    return false;
  }

  private handleNotAuthenticatedError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.authService.logout();
      return EMPTY;
    }
    return throwError(err);
  }
}
