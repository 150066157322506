import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ReviewRealtimeService } from "./review/review-realtime.service";
import { Router } from "@angular/router";
import { AuthService } from "./auth/services/auth.service";
import { NotificationService } from "./notification/notification.service";
import { AuthStorageListenerService } from "./auth/services/auth.storage-listener.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy {
  public constructor(
    private authService: AuthService,
    private authStorageListenerService: AuthStorageListenerService,
    private readonly titleService: Title,
    private readonly reviewRealtimeService: ReviewRealtimeService,
    private readonly router: Router,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.notificationService.initialiseNotifications();
  }

  public ngOnDestroy(): void {
    this.notificationService.endNotification();
  }
}
