<h1>{{ systemControlTitle }}</h1>
<app-routing-back></app-routing-back>

<ng-container *ngIf="isLoadingData">
  <mat-spinner></mat-spinner>
</ng-container>

<ng-container *ngIf="!isLoadingData">
  <mat-card>
    <mat-card-title>
      {{ healthcheckTitle }}
    </mat-card-title>
    <div class="table-wrapper">
      <table class="healthcheck-table">
        <thead>
          <tr>
            <th scope="col">{{ healthcheckHeaderSystemLabel }}</th>
            <th scope="col">{{ healthcheckHeaderStatusLabel }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of getTableRows()">
            <tr>
              <td>
                {{ row.label }}
              </td>
              <td>
                <app-health-icon [health]="row.healthStatus"></app-health-icon>
              </td>
              <td>
                <button
                  mat-raised-button
                  [disabled]="row.details === null && row.statusInfo === null"
                  (click)="showComponentDetailsDialog(row)"
                  class="detail-button"
                  color="primary"
                >
                  {{ healthcheckDetailsButtonLabel }}
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </mat-card>
</ng-container>
