/**
 * Used to store credentials and send events.
 */
export type StorageItem = typeof storageItems[number];

export const storageItems = [
  "access_token",
  "refresh_token",
  "expires_at",
  "permissions",
  "username",
  "share_credentials_request",
  "flush_credentials_request",
  "credentials_sharing"
] as const;
