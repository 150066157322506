import {Component, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map, shareReplay} from "rxjs/operators";
import {default as STRINGS} from "../../assets/strings.json";
import {appRoutePermission, AppRoutes} from "../app.routes";
import {AuthService} from "../auth/services/auth.service";
import {SubscribingComponent} from "../shared/subscribing/subscribing.component";
import {PermissionType} from "../roles-and-rights/model/Permission";
import {environment} from "../../environments/environment"

type MenuLink = {
    title: string;
    link: string;
    icon?: string;
};

type MenuLinkCategory = {
    title: string;
    links: MenuLink[];
};

@Component({
    selector: "app-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent
    extends SubscribingComponent
    implements OnInit, OnDestroy {
    static readonly menuLinkCategoriesOrg: MenuLinkCategory[] = [
        {
            title: STRINGS.startCategoryTitle,
            links: [
                {
                    title: STRINGS.homeLinkTitle,
                    link: "/",
                    icon: "home",
                },
                {
                    title: STRINGS.accountLinkTitle,
                    link: AppRoutes.ACCOUNT,
                    icon: "account_circle",
                },
            ],
        },
        {
            title: STRINGS.adminCategoryTitle,
            links: [
                {
                    title: STRINGS.partnerOverviewTitle,
                    link: AppRoutes.PARTNER,
                    icon: "business",
                },
                {
                    title: STRINGS.rolesAndRightsTitle,
                    link: AppRoutes.ROLES_AND_RIGHTS,
                    icon: "manage_accounts",
                },
            ],
        },
        {
            title: STRINGS.legitimationCategoryTitle,
            links: [
                {
                    title: STRINGS.legitimationsTitle,
                    link: AppRoutes.LEGITIMATIONS,
                    icon: "fingerprint",
                },
                {
                    title: STRINGS.aiAdminTitle,
                    link: AppRoutes.AI_THRESHOLDS,
                    icon: "settings",
                },
            ],
        },
        {
            title: STRINGS.evaluationCategoryTitle,
            links: [
                {
                    title: STRINGS.ocrRequestsTitle,
                    link: AppRoutes.OCR_REQUESTS,
                    icon: "notes",
                },
                {
                    title: STRINGS.statisticsTitle,
                    link: AppRoutes.STATISTICS,
                    icon: "bar_chart",
                },
            ],
        },
        {
            title: STRINGS.systemCategoryTitle,
            links: [
                {
                    title: STRINGS.systemControlTitle,
                    link: AppRoutes.SYSTEM_CONTROL,
                    icon: "lightbulb",
                },
            ],
        },
    ];

    menuLinkCategories: typeof NavigationComponent.menuLinkCategoriesOrg = [];
    headerTitle: string = STRINGS.headerTitle + environment.envTile;
    menuTitle: string = STRINGS.menuTitle;
    isLoggedIn = false;

    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map((result) => result.matches),
            shareReplay()
        );

    private subscription: Subscription | undefined;

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly authService: AuthService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscription = this.subscribe(
            this.authService.loggedIn$,
            (loggedIn) => {
                this.isLoggedIn = loggedIn;
                if (loggedIn) {
                    this.initCategories(this.authService.getPermissions());
                }
            }
        );
    }

    logout(): void {
        this.authService.logout();
    }

    private initCategories(permissions: PermissionType[]): void {
        const categoriesWithFilteredLinks: typeof NavigationComponent.menuLinkCategoriesOrg = NavigationComponent.menuLinkCategoriesOrg.map(
            (category) => {
                const filteredLinks = category.links.filter((menuLink) =>
                    this.isLinkAccessible(menuLink, permissions)
                );
                return {links: filteredLinks, title: category.title};
            }
        );
        this.menuLinkCategories = categoriesWithFilteredLinks.filter(
            (cat) => cat.links.length > 0
        );
    }

    private isLinkAccessible(
        menuLink: MenuLink,
        permissions: PermissionType[]
    ): boolean {
        const routePermission = appRoutePermission[menuLink.link as AppRoutes];
        return routePermission
            ? permissions.includes(routePermission as PermissionType)
            : true;
    }
}
