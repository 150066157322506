import { SystemControlModule } from "./system-control/system-control.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { JsonPipe } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StartPageComponent } from "./start-page/start-page.component";
import { MatCardModule } from "@angular/material/card";
import { NotFoundComponent } from "./not-found.component";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { LayoutModule } from "@angular/cdk/layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { NavigationComponent } from "./navigation/navigation.component";
import { FooterComponent } from "./footer/footer.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { AuthService } from "./auth/services/auth.service";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { AngularSplitModule } from "angular-split";
import { AngularResizedEventModule } from "angular-resize-event";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ColorPickerModule } from "ngx-color-picker";

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    NotFoundComponent,
    NavigationComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatRippleModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    HttpClientModule,
    MatDialogModule,
    SystemControlModule,
    NgxImageZoomModule,
    AngularSplitModule,
    AngularResizedEventModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ColorPickerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService]
    },
    JsonPipe
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
