import { Component, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";

/**
 * Base class to handle subscriptions via the lifecycle of a component.
 *
 * If you're extending this class and implement your own ngOnDestroy, remember to call super.ngOnDestroy() to get rid of subscriptions.
 */
@Component({
  template: "<div>Should never be seen.</div>",
})
export class SubscribingComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  protected subscribe<T>(
    observable: Observable<T>,
    next?: (value: T) => void,
    error?: (error: any) => void,
    complete?: () => void
  ): Subscription {
    const sub = observable.subscribe(next, error, complete);

    this.subscriptions.push(sub);

    return sub;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }
}
