import { Component, OnInit } from "@angular/core";
import { appRoutePermission, AppRoutes } from "../app.routes";
import { default as STRINGS }  from "../../assets/strings.json";
import { HealthcheckService } from "../system-control/healthchecks/services/healthcheck.service";
import { PermissionType } from "../roles-and-rights/model/Permission";
import { AuthService } from "../auth/services/auth.service";

type NavigationCard = {
  title: string;
  content: string;
  link: AppRoutes;
  disabled?: true;
  isSystemControl?: boolean;
};

@Component({
  selector: "app-start-page",
  templateUrl: "./start-page.component.html",
  styleUrls: ["./start-page.component.css"],
})
export class StartPageComponent implements OnInit {
  private static readonly navigationCardsOrg: NavigationCard[] = [
    {
      title: STRINGS.partnerOverviewTitle,
      content: STRINGS.partnerOverviewDescription,
      link: AppRoutes.PARTNER,
    },
    {
      title: STRINGS.aiAdminTitle,
      content: STRINGS.aiAdminDescription,
      link: AppRoutes.AI_THRESHOLDS,
    },
    {
      title: STRINGS.systemControlTitle,
      content: STRINGS.systemControlDescription,
      link: AppRoutes.SYSTEM_CONTROL,
      isSystemControl: true,
    },
    {
      title: STRINGS.rolesAndRightsTitle,
      content: STRINGS.rolesAndRightsDescription,
      link: AppRoutes.ROLES_AND_RIGHTS,
    },
    {
      title: STRINGS.statisticsTitle,
      content: STRINGS.statisticsDescription,
      link: AppRoutes.STATISTICS,
    },
    {
      title: STRINGS.secondHumanEyeTitle,
      content: STRINGS.secondHumanEyeDescription,
      link: AppRoutes.SECOND_HUMAN_EYE_STATISTICS,
    },
    {
      title: STRINGS.legitimationsTitle,
      content: STRINGS.legitimationsDescription,
      link: AppRoutes.LEGITIMATIONS,
    },
    {
      title: STRINGS.reviewTitle,
      content: STRINGS.reviewDescription,
      link: AppRoutes.REVIEW,
    },
    {
      title: STRINGS.ocrRequestsTitle,
      content: STRINGS.ocrRequestsDescription,
      link: AppRoutes.OCR_REQUESTS,
    },
    {
      title: STRINGS.internalControlSystemTitle,
      content: STRINGS.internalControlSystemDescription,
      link: AppRoutes.INTERNAL,
      disabled: true,
    },
  ];

  isAllSystemsUp?: boolean;

  public navigationCards: typeof StartPageComponent.navigationCardsOrg = [];

  constructor(
    private readonly healthcheckService: HealthcheckService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.healthcheckService.isAllSystemsUp().subscribe((res) => {
      this.isAllSystemsUp = res.valueOf();
    });
    const permissions = this.authService.getPermissions();
    this.navigationCards = StartPageComponent.navigationCardsOrg.filter(
      (card) => this.isCardAccessible(card, permissions)
    );
  }

  private isCardAccessible(
    card: NavigationCard,
    permissions: PermissionType[]
  ) {
    const routePermission = appRoutePermission[card.link];
    return routePermission
      ? permissions.includes(routePermission as PermissionType)
      : true;
  }
}
