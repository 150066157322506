export interface Environment {
  production: boolean;
  api: AdminApi;
  envTile: string;
  client: {
    clientName: string;
    clientSecret: string;
  };
}

/** The endpoints provided by the admin API. */
export interface AdminApi {
  account: {
    /**
     * Not parameterized.
     */
    changePassword: string;
  };
  partner: {
    /**
     * Not parameterized.
     */
    overview: string;
    /**
     * Parameters:
     *
     * {0}: The Partner ID.
     */
    details: string;
    /**
     * Parameters:
     *
     * {0}: The Partner ID.
     */
    resetPassword: string;
    /**
     * Parameters:
     *
     * {0}: The Partner short name.
     */
    uploadLogo: string;
    /**
     * Parameters:
     *
     * {0}: The partner shortname.
     * {1}: The logo type.
     */
    getLogo: string;

    /**
     * Parameters:
     *
     * {0}: The partner shortname.
     * {1}: The logo type.
     */
    deleteLogo: string;

    /**
     * Parameters:
     *
     * {0}: The Partner short name.
     * {1}: The font style.
     * {2}: The font weight.
     */
    uploadFont: string;

    /**
     * Parameters:
     *
     * {0}: The Partner short name.
     * {1}: The font style.
     * {2}: The font weight.
     */
    deleteFont: string;

    /**
     * Parameters:
     *
     * {0}: The Partner ID.
     */
    statistics: string;
  };
  checkConfiguration: {
    /**
     * Not parameterized.
     */
    overview: string;
  };
  legitimation: {
    /**
     * Not parameterized.
     */
    overview: string;
    /**
     * Not parameterized.
     */
    csv: string;
    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    details: string;

    /**
     * Not parameterized.
     */
    startReview: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    rerunChecks: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    callback: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The image type.
     */
    image: string;

    /**
     * Parameters:
     *
     * {0}: The app document type.
     *
     * {1}: The image type.
     */
    sample: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The video type.
     */
    video: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The check type.
     */
    check: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    personChecks: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The check type.
     */
    licenseCheck: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    idExtraction: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    licenseExtraction: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The selfie video type.
     *
     * {2}: The selfie video index
     */
    selfieVideo: string;

    /**
     * Parameters:
     *
     * {0}: Document Type
     *
     * {1}: Hologram Check
     */
    holoSample: string;

    /**
     * Not parameterized.
     *
     */
    partners: string;

    /**
     * {0}: The legitimation ID.
     *
     */

    holoFrames: string;

    /**
     * {0}: The legitimation ID.
     */
    updateFrontId: string;
  };
  supervisor: {
    /**
     * Not parameterized.
     */
    allReviewers: string;
    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The username to assign as reviewer.
     */
    assignReviewer: string;
    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The check type.
     */
    resetCheck: string;
    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The check type.
     */
    resetLicenseCheck: string;
    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    archive: string;
  };
  ocrRequests: {
    /**
     * Not parameterized.
     */
    overview: string;
    /**
     * Parameters:
     *
     * {0}: The OCR request ID.
     */
    details: string;
    /**
     * Parameters:
     *
     * {0}: The OCR request ID.
     */
    image: string;
    /**
     * Not parameterized.
     */
    csv: string;
  };
  statistics: {
    /**
     * Not parameterized.
     */
    legitimations: string;
    /**
     * Not parameterized.
     */
    ocr: string;
    /**
     * Not parameterized.
     */
    avgStatusTimes: string;
    /**
     * Not parameterized.
     */
    performanceReport: string;
    /**
     * Not parameterized.
     */
    plausibilityChecks: string;
    /**
     * Not parameterized.
     */
    appRatings: string;
    /**
     * Not parameterized.
     */
    confidenceScores: string;

    checkFailures: string;

    finalLegitimations: string;
    legitimationStepAvgTime: string;
  };
  auth: {
    /**
     * Not parameterized.
     */
    login: string;
  };
  actuator: {
    /**
     * Not parameterized.
     */
    health: string;
    /**
     * Not parameterized.
     */
    info: string;
  };
  secondHumanEyeStatistics: {
    /**
     * Not parameterized.
     */
    denialReasons: string;
    /**
     * Not parameterized.
     */
    legitimations: string;
    /**
     * Not parameterized.
     */
    averageResolution: string;
    /**
     * Not parameterized.
     */
    totalResolution: string;

    /**
     * Not parameterized.
     */
    averageAcceptToInReview: string;
    /**
     * Not parameterized.
     */
    countWithinSLA: string;

    /**
     * Not parameterized.
     */
    countWithoutSLALowerThanTen: string;
    /**
     * Not parameterized.
     */
    countWithoutSLAHigherThanTen: string;
    /**
     * Not parameterized.
     */
    listWithoutSLAHigherThanTen: string;
  };
  adminApiBaseUrl: string;
  roles: {
    /**
     * Not parameterized.
     */
    overview: string;
  };
  users: {
    /**
     * Not parameterized.
     */
    overview: string;
    /**
     * Parameters:
     *
     * {0}: The user ID.
     */
    details: string;

    /**
     * Parameters:
     *
     * {0}: The user ID.
     */
    resetPassword: string;
  };
  realtimedashboard: {
    /**
     * Not parameterized.
     */
    overview: string;
  };
}

/** Creates an environment API based on a coreHost. */
export const createEnvironmentApi = (coreHost: string, aiHost: string): AdminApi => {
  const coreUrl = coreHost + "/admin/v1";
  return {
    account: {
      changePassword: `${coreUrl}/my-account/change-password`
    },
    partner: {
      overview: `${coreUrl}/partners`,
      details: `${coreUrl}/partners/{0}`,
      resetPassword: `${coreUrl}/partners/{0}/reset_password`,
      uploadLogo: `${coreUrl}/partners/{0}/upload_logo/{1}`,
      getLogo: `${coreUrl}/partners/get_logo/{0}/{1}`,
      deleteLogo: `${coreUrl}/partners/{0}/delete_logo/{1}`,
      uploadFont: `${coreUrl}/partners/{0}/upload_font/{1}/{2}`,
      deleteFont: `${coreUrl}/partners/{0}/delete_font/{1}/{2}`,
      statistics: `${coreUrl}/partners/{0}/statistics`
    },
    checkConfiguration: {
      overview: `${coreUrl}/checks`
    },
    legitimation: {
      overview: `${coreUrl}/legitimations?`,
      csv: `${coreUrl}/legitimations/csv-export`,
      details: `${coreUrl}/legitimations/{0}`,
      startReview: `${coreUrl}/legitimations/start-review`,
      rerunChecks: `${coreUrl}/legitimations/{0}/rerun-checks`,
      callback: `${coreUrl}/legitimations/{0}/retry-callback`,
      image: `${coreUrl}/legitimations/{0}/images/{1}`,
      sample: `${coreUrl}/sample/{0}/{1}`,
      video: `${coreUrl}/legitimations/{0}/videos/{1}`,
      check: `${coreUrl}/legitimations/{0}/checks/{1}`,
      personChecks: `${coreUrl}/legitimations/{0}/person-checks`,
      licenseCheck: `${coreUrl}/legitimations/{0}/license-checks/{1}`,
      idExtraction: `${coreUrl}/legitimations/{0}/id-extraction`,
      licenseExtraction: `${coreUrl}/legitimations/{0}/license-extraction`,
      selfieVideo: `${coreUrl}/legitimations/{0}/selfievideos/{1}/{2}`,
      holoSample: `${coreUrl}/sample/hologram/{0}/{1}`,
      holoFrames: `${coreUrl}/legitimations/video-frames/{0}`,
      updateFrontId: `${coreUrl}/legitimations/update-frontid/{0}`,
      partners: `${coreUrl}/legitimations/partners`
    },
    supervisor: {
      allReviewers: `${coreUrl}/supervisor/reviewers`,
      resetCheck: `${coreUrl}/supervisor/{0}/reset-check/{1}`,
      resetLicenseCheck: `${coreUrl}/supervisor/{0}/reset-license-check/{1}`,
      assignReviewer: `${coreUrl}/supervisor/{0}/assign-reviewer/{1}`,
      archive: `${coreUrl}/supervisor/{0}/archive`
    },
    ocrRequests: {
      overview: `${coreUrl}/ocr-requests?`,
      details: `${coreUrl}/ocr-requests/{0}`,
      image: `${coreUrl}/ocr-requests/{0}/image`,
      csv: `${coreUrl}/ocr-requests/csv-export`
    },
    statistics: {
      legitimations: `${coreUrl}/statistics/timestampsOfLegitimations`,
      ocr: `${coreUrl}/statistics/listOfInstantsOfextractionRequests`,
      avgStatusTimes: `${coreUrl}/statistics/averageTimeInStatus`,
      performanceReport: `${coreUrl}/statistics/performanceReport`,
      plausibilityChecks: `${coreUrl}/statistics/numberOfFailedFields`,
      confidenceScores: `${coreUrl}/statistics/confidenceScores`,
      appRatings: `${coreUrl}/statistics/appRatings`,
      checkFailures: `${coreUrl}/statistics/checkFailures`,
      finalLegitimations: `${coreUrl}/statistics/finalLegitimations`,
      legitimationStepAvgTime: `${coreUrl}/statistics/appLegitimationStepAvgTime`
    },

    secondHumanEyeStatistics: {
      denialReasons: `${coreUrl}/second-human-eye-statistics/denialReasons`,
      legitimations: `${coreUrl}/second-human-eye-statistics/timestampsOfLegitimations`,
      averageResolution: `${coreUrl}/second-human-eye-statistics/averageTime`,
      totalResolution: `${coreUrl}/second-human-eye-statistics/totalTime`,
      averageAcceptToInReview: `${coreUrl}/second-human-eye-statistics/averageAcceptToInReview`,
      countWithinSLA: `${coreUrl}/second-human-eye-statistics/countWithinSLA`,
      countWithoutSLALowerThanTen: `${coreUrl}/second-human-eye-statistics/countWithoutSLALowerThanTen`,
      countWithoutSLAHigherThanTen: `${coreUrl}/second-human-eye-statistics/countWithoutSLAHigherThanTen`,
      listWithoutSLAHigherThanTen: `${coreUrl}/second-human-eye-statistics/listWithoutSLAHigherThanTen`
    },
    auth: {
      login: `${coreHost}/oauth/token`
    },
    actuator: {
      health: `${coreHost}/actuator/health`,
      info: `${coreHost}/actuator/info`
    },
    adminApiBaseUrl: coreUrl,
    roles: {
      overview: `${coreUrl}/roles`
    },
    users: {
      overview: `${coreUrl}/users`,
      details: `${coreUrl}/users/{0}`,
      resetPassword: `${coreUrl}/users/{0}/reset_password`
    },
    realtimedashboard: {
      overview: `${coreUrl}/number-of-legitimations`
    }
  };
};
