import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { default as STRINGS } from "../../../../assets/strings.json";
import { StringsUtil } from "../../../shared/util/strings.util";


@Component({
  selector: "app-details-dialog",
  templateUrl: "./details-dialog.component.html",
  styleUrls: ["./details-dialog.component.css"]
})
export class DetailsDialogComponent implements OnInit {
  systemControlDetailsTitle!: string;
  details!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { details: any, componentName: string }) {
  }

  ngOnInit() {
    this.systemControlDetailsTitle = StringsUtil.format(
      STRINGS.systemControlDetailsDialogTitle,
      this.data.componentName);
    this.details = JSON.stringify(this.data.details, null, 3);
  }
}
