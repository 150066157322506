import { Component } from "@angular/core";
import { default as STRINGS }  from "../assets/strings.json";

@Component({
  template: `
    <h1>{{ pageNotFoundTitle }}</h1>
    <div>{{ pageNotFoundContent }}</div>
  `,
  styles: [],
})
export class NotFoundComponent {
  pageNotFoundTitle = STRINGS.pageNotFoundTitle;
  pageNotFoundContent = STRINGS.pageNotFoundContent;
}
