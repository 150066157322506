import { Injectable, OnDestroy } from "@angular/core";
import { AuthService } from "./auth.service";
import { TokenResponse } from "../model/TokenResponse";
import jwtDecode from "jwt-decode";
import { DecodedJwt } from "../model/AccessToken";
import { StorageItem, storageItems } from "../model/StorageItem";

const accessTokenItem: StorageItem = storageItems[0];
const refreshTokenItem: StorageItem = storageItems[1];
const expiresAtItem: StorageItem = storageItems[2];
const shareCredentialsRequestItem: StorageItem = storageItems[5];
const flushCredentialsRequestItem: StorageItem = storageItems[6];
const credentialsSharingItem: StorageItem = storageItems[7];

@Injectable({
  providedIn: "root"
})
export class AuthStorageListenerService implements OnDestroy {
  private readonly storageEventListener: any;

  constructor(private authService: AuthService) {
    this.storageEventListener = (event: StorageEvent) => {
      this.onStorageChange(event);
    };
    window.addEventListener("storage", this.storageEventListener);
  }

  private static shareCredentials(sessionToken: TokenResponse): void {
    localStorage.setItem(credentialsSharingItem, JSON.stringify({ token: sessionToken }));
    localStorage.removeItem(credentialsSharingItem);
  }

  ngOnDestroy(): void {
    if (this.storageEventListener) {
      window.removeEventListener("storage", this.storageEventListener);
    }
  }

  private onStorageChange(event: StorageEvent): void {
    const sessionToken: TokenResponse | null = this.getSessionToken();

    if (event.key === shareCredentialsRequestItem && sessionToken) {
      AuthStorageListenerService.shareCredentials(sessionToken);
    }
    if (event.key === credentialsSharingItem && !sessionToken) {
      this.setNewSession(event);
    }
    if (event.key === flushCredentialsRequestItem && sessionToken) {
      AuthService.clearSession();
    }
  }

  private getSessionToken(): TokenResponse | null {
    const accessToken = sessionStorage.getItem(accessTokenItem);
    const refreshToken = sessionStorage.getItem(refreshTokenItem);
    const expirationTime = sessionStorage.getItem(expiresAtItem);
    let sessionToken: TokenResponse | null = null;

    if (accessToken && refreshToken && expirationTime) {
      const decodedToken = jwtDecode<DecodedJwt>(accessToken);
      sessionToken = {
        access_token: accessToken,
        token_type: "bearer",
        refresh_token: refreshToken,
        expires_in: parseInt(expirationTime, 10),
        jti: decodedToken.jti,
        scope: "USE_DASHBOARD"
      };
    }

    return sessionToken;
  }

  private setNewSession(event: StorageEvent): void {
    if (event.newValue) {
      const sharedSession = JSON.parse(event.newValue).token;
      const newSession: TokenResponse = {
        access_token: sharedSession.access_token,
        token_type: sharedSession.token_type,
        refresh_token: sharedSession.refresh_token,
        expires_in: sharedSession.expires_in,
        scope: sharedSession.scope,
        jti: sharedSession.jti
      };

      this.authService.setSession(newSession);
    }
  }
}
