import { NgModule } from "@angular/core";
import { TablePagingComponent } from "./table/table-paging/table-paging.component";
import { MatTableModule } from "@angular/material/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { SubscribingComponent } from "./subscribing/subscribing.component";
import { ErrorComponent } from "./error/error.component";
import { MatCardModule } from "@angular/material/card";
import { RoutingBackComponent } from "./routing-back/routing-back.component";
import { ConfirmationComponent } from "./confirmation/confirmation.component";
import { MatDialogModule } from "@angular/material/dialog";
import { LocalDatePipe } from "./local-date.pipe";
import { MatSortModule } from "@angular/material/sort";
import { EnlargeableImageComponent } from "./enlargeable-image/enlargeable-image.component";
import { ImagePopupComponent } from "./enlargeable-image/image-popup/image-popup.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { OnDemandVideoComponent } from "./on-demand-video/on-demand-video.component";
import { DisplayPasswordComponent } from "./display-password/display-password.component";
import { NotificationComponent } from "./notification/notification.component";
import { ExtendedRouterlinkDirective } from "./extended-routerlink.directive";
import { TableComponent } from "./table/table.component";
import { HintComponent } from "./hint/hint.component";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { AngularResizedEventModule } from "angular-resize-event";
import { StatisticFilterComponent } from "./statistic-filter/statistic-filter.component";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AcceptanceIconComponent } from "./acceptance-icon/acceptance-icon.component";
import { ListComponent } from "./list/list.component";
import { MatListModule } from "@angular/material/list";
import { ColorPickerModule } from "ngx-color-picker";
import { MillisToTimePipe } from "./millis-to-time.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { DropdownSearchComponent } from "./dropdown-search/dropdown-search.component";
import { ToggleAllComponent } from "./toggle-all/toggle-all.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TableFilterComponent } from "./table/table-filter/table-filter.component";
import { CsvDownloadComponent } from "./csv-download/csv-download.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    ExtendedRouterlinkDirective,
    TablePagingComponent,
    TableComponent,
    SubscribingComponent,
    ErrorComponent,
    RoutingBackComponent,
    ConfirmationComponent,
    LocalDatePipe,
    EnlargeableImageComponent,
    ImagePopupComponent,
    OnDemandVideoComponent,
    DisplayPasswordComponent,
    NotificationComponent,
    HintComponent,
    StatisticFilterComponent,
    AcceptanceIconComponent,
    ListComponent,
    MillisToTimePipe,
    DropdownSearchComponent,
    ToggleAllComponent,
    TableFilterComponent,
    CsvDownloadComponent
  ],
  imports: [
    MatTableModule,
    FormsModule,
    MatInputModule,
    RouterModule.forChild([]),
    MatButtonModule,
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDialogModule,
    MatSortModule,
    MatPaginatorModule,
    NgxImageZoomModule,
    AngularResizedEventModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatListModule,
    ColorPickerModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
  exports: [
    TablePagingComponent,
    TableComponent,
    SubscribingComponent,
    ErrorComponent,
    RoutingBackComponent,
    ConfirmationComponent,
    LocalDatePipe,
    EnlargeableImageComponent,
    OnDemandVideoComponent,
    DisplayPasswordComponent,
    HintComponent,
    NotificationComponent,
    StatisticFilterComponent,
    AcceptanceIconComponent,
    ListComponent,
    MillisToTimePipe,
    DropdownSearchComponent,
    ToggleAllComponent,
    TableFilterComponent
  ]
})
export class SharedModule {}
