<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <img src="assets/img/qundo-big.svg" alt="Qundo" id="logo" />
    <mat-divider></mat-divider>

    <ng-container *ngIf="isLoggedIn">
      <mat-toolbar>{{ menuTitle }}</mat-toolbar>
      <mat-nav-list>
        <ng-container *ngFor="let category of menuLinkCategories">
          <mat-divider></mat-divider>
          <h3 matSubheader>{{ category.title }}</h3>
          <mat-divider></mat-divider>
          <ng-container *ngFor="let menuEntry of category.links">
            <mat-list-item>
              <a matLine [routerLink]="menuEntry.link"> {{ menuEntry.title }}</a>
              <mat-icon *ngIf="menuEntry.icon" aria-hidden="false">{{ menuEntry.icon }}</mat-icon>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </mat-nav-list>
      <mat-action-list>
        <button mat-list-item (click)="logout()">Logout</button>
      </mat-action-list>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <div>
        <span [innerHTML]="headerTitle"></span>
      </div>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
