import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { AuthRoutes } from "./auth.routes";
import { appRoutePermission, AppRoutes } from "../app.routes";
import { Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      debounceTime(10),
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.router.navigate([AppRoutes.AUTH + "/" + AuthRoutes.LOGIN]);
          this.authService.redirectUrl = state.url;
          return false;
        }
        if (route.url.length) {
          const requiredPermission = appRoutePermission[(route.url[0] as unknown) as AppRoutes];
          if (requiredPermission && !this.authService.hasPermission(requiredPermission)) {
            this.router.navigate([]); // Just go to home if access denied to route.
            return false;
          }
        }
        return true;
      })
    );
  }
}
