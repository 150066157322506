import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StartPageComponent } from "./start-page/start-page.component";
import { NotFoundComponent } from "./not-found.component";
import { AppRoutes } from "./app.routes";
import { AuthGuard } from "./auth/auth.guard";

const ANGULAR_ROUTES: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: StartPageComponent,
    canActivate: [AuthGuard],
  },

  {
    path: AppRoutes.AUTH,
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path: AppRoutes.PARTNER,
    loadChildren: () =>
      import("./partner/partner.module").then((m) => m.PartnerModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.AI_THRESHOLDS,
    loadChildren: () =>
      import("./ai-threshold/ai-threshold.module").then(
        (m) => m.AiThresholdModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.LEGITIMATIONS,
    loadChildren: () =>
      import("./legitimation/legitimation.module").then(
        (m) => m.LegitimationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.REVIEW,
    loadChildren: () =>
      import("./review/review.module").then((m) => m.ReviewModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.OCR_REQUESTS,
    loadChildren: () =>
      import("./ocr-requests/ocr-requests.module").then(
        (m) => m.OcrRequestsModule
      ),
  },
  {
    path: AppRoutes.STATISTICS,
    loadChildren: () =>
      import("./statistic/statistic.module").then((m) => m.StatisticModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.SECOND_HUMAN_EYE_STATISTICS,
    loadChildren: () =>
      import(
        "./second-human-eye-statistic/second-human-eye-statistic.module"
      ).then((m) => m.SecondHumanEyeStatisticModule),
    canActivate: [AuthGuard],
  },

  {
    path: AppRoutes.SYSTEM_CONTROL,
    loadChildren: () =>
      import("./system-control/system-control.module").then(
        (m) => m.SystemControlModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.ROLES_AND_RIGHTS,
    loadChildren: () =>
      import("./roles-and-rights/roles-and-rights.module").then(
        (m) => m.RolesAndRightsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.ACCOUNT,
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    component: NotFoundComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ANGULAR_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
