import { Component, Input } from "@angular/core";

@Component({
  selector: "app-health-icon",
  templateUrl: "./health-icon.component.html",
  styleUrls: ["./health-icon.component.scss"]
})
export class HealthIconComponent {
  @Input()
  health!: boolean | null;
}
