<nav class="card-menu">
  <mat-card
    mat-ripple
    *ngFor="let card of navigationCards"
    [routerLink]="card.link"
    class="mat-elevation-z2"
    [ngClass]="{ disabled: card.disabled }"
  >
    <div>
      <mat-card-title>{{ card.title }}</mat-card-title>
      <mat-card-content>{{ card.content }}</mat-card-content>
      <div *ngIf="card.isSystemControl! && !isAllSystemsUp" style="text-align: right">
        <app-health-icon [health]="false"></app-health-icon>
      </div>
      </div>
  </mat-card>
</nav>
