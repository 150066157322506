import { HealthcheckRequestAdapter } from "./healthcheck.adapter";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HealthComponent, HealthStatusOfCore } from "../HealthStatusOfCore";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class HealthcheckService {
  constructor(private healthcheckRequestAdapter: HealthcheckRequestAdapter) {}

  public isCoreUp(): Observable<boolean> {
    return this.getCoreHealthStatus().pipe(HealthcheckService.mapToTrueIfStatus200());
  }

  public getWholeCoreHealth(): Observable<HealthStatusOfCore> {
    return this.getCoreHealthStatus().pipe(
      map((res: HttpResponse<Response>) => {
        return HealthcheckService.mapHealthDtoToHealth(res.body);
      }),
      catchError((res: HttpErrorResponse) => {
        console.error("Cannot fetch health status", res.error, res);
        // TODO (review) The following line is not correct: response does not contain the status.
        return of(HealthcheckService.mapHealthDtoToHealth(res.error));
      })
    );
  }

  public isAllSystemsUp(): Observable<boolean> {
    return this.isCoreUp(); // TODO Combined status should consider all sub-systems.
  }

  public getCoreHealthInfoRequest(): Observable<Response> {
    return this.healthcheckRequestAdapter.getCoreHealthInfoRequest();
  }

  private getCoreHealthStatus(): Observable<HttpResponse<Response>> {
    return this.healthcheckRequestAdapter.getCoreHealthCheckRequest();
  }

  private static mapToTrueIfStatus200() {
    return map(
      (res: HttpResponse<Response>) => res.status === 200,
      catchError(() => of(false))
    );
  }

  private static mapHealthDtoToHealth(dto: any): HealthStatusOfCore {
    return {
      status: this.isUp(dto.status),
      database: HealthcheckService.mapComponentDto(dto.components.db),
      argo: HealthcheckService.mapComponentDto(dto.components.argo),
      aiWrapper: HealthcheckService.mapComponentDto(dto.components.aiWrapper),
      loki: HealthcheckService.mapComponentDto(dto.components.loki)
    };
  }

  private static mapComponentDto(dto: any): HealthComponent {
    return {
      status: this.isUp(dto.status),
      details: dto.details
    };
  }

  private static isUp(status: any) {
    return status === "UP";
  }
}
