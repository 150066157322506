import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map, shareReplay, take, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import moment, { Moment, now } from "moment";
import { TokenResponse } from "../model/TokenResponse";
import { DecodedJwt, EncodedToken } from "../model/AccessToken";
import { Router } from "@angular/router";
import { AppRoutes } from "../../app.routes";
import { AuthRoutes } from "../auth.routes";
import jwtDecode from "jwt-decode";
import { PermissionType } from "../../roles-and-rights/model/Permission";
import { StorageItem, storageItems } from "../model/StorageItem";

const accessTokenItem: StorageItem = storageItems[0];
const refreshTokenItem: StorageItem = storageItems[1];
const expiresAtItem: StorageItem = storageItems[2];
const permissionsItem: StorageItem = storageItems[3];
const usernameItem: StorageItem = storageItems[4];
const shareCredentialsRequestItem: StorageItem = storageItems[5];
const flushCredentialsRequestItem: StorageItem = storageItems[6];

@Injectable({
  providedIn: "root"
})
export class AuthService {
  loggedIn$: BehaviorSubject<boolean>;
  redirectUrl: string | null;

  private readonly clientAuthorizationHeader: string;

  constructor(private http: HttpClient, private router: Router) {
    const { clientName, clientSecret } = environment.client;
    const decoded = `${clientName}:${clientSecret}`;
    this.clientAuthorizationHeader = "Basic " + btoa(decoded);
    this.loggedIn$ = new BehaviorSubject<boolean>(AuthService.init());
    this.redirectUrl = null;
  }

  public static clearSession(): void {
    sessionStorage.removeItem(accessTokenItem);
    sessionStorage.removeItem(refreshTokenItem);
    sessionStorage.removeItem(expiresAtItem);
    sessionStorage.removeItem(permissionsItem);
    sessionStorage.removeItem(usernameItem);
  }

  private static createLoginRequest(username: string, password: string): URLSearchParams {
    const request = new URLSearchParams();
    request.append("username", username);
    request.append("password", password);
    request.append("grant_type", "password");
    return request;
  }

  private static createRefreshTokenRequest(refreshToken: string): URLSearchParams {
    const request = new URLSearchParams();
    request.append("grant_type", "refresh_token");
    request.append("refresh_token", refreshToken);
    return request;
  }

  private static getEncodedRefreshToken(): EncodedToken | null {
    return sessionStorage.getItem(refreshTokenItem);
  }

  private static decodeToken(token: string): DecodedJwt {
    return jwtDecode<DecodedJwt>(token);
  }

  private static getExpiration(): Moment | null {
    const expiration = sessionStorage.getItem(expiresAtItem);
    if (expiration === null) {
      return null;
    }
    const expiresAt: string = JSON.parse(expiration);
    return moment(expiresAt);
  }

  private static init(): boolean {
    if (!moment().isBefore(AuthService.getExpiration())) {
      AuthService.clearSession();
      localStorage.setItem(shareCredentialsRequestItem, moment().format());
      localStorage.removeItem(shareCredentialsRequestItem);
      return false;
    }
    return true;
  }

  public isLoggedIn(): Observable<boolean> {
    if (AuthService.getExpiration()?.isBefore(now())) {
      return this.performTokenRefresh().pipe(
        take(1),
        map(() => this.loggedIn$.value)
      );
    }
    return this.loggedIn$;
  }

  public login(username: string, password: string): Observable<void> {
    return this.postForToken(AuthService.createLoginRequest(username, password)).pipe(
      tap((response) => {
        this.setSession(response);
      }),
      shareReplay(1, 180),
      map(() => {
        /* Don't expose access token unnecessarily. */
      })
    );
  }

  public performTokenRefresh(): Observable<any> {
    const refreshToken = AuthService.getEncodedRefreshToken();
    if (!refreshToken) {
      this.logout();
      return of();
    }
    console.log("Trying to refresh token...");
    const request = AuthService.createRefreshTokenRequest(refreshToken);

    return this.postForToken(request).pipe(
      tap((response) => {
        console.log("Token refreshed!");
        this.setSession(response);
      }),
      shareReplay(1, 180),
      map(() => {
        /* Don't expose tokens unnecessarily. */
      }),
      catchError((err) => {
        console.log("Error refreshing token!");
        this.logout();
        return throwError(err);
      })
    );
  }

  public logout(): void {
    AuthService.clearSession();
    localStorage.setItem(flushCredentialsRequestItem, moment().format());
    localStorage.removeItem(flushCredentialsRequestItem);
    this.loggedIn$.next(false);
    this.router.navigate([AppRoutes.AUTH + "/" + AuthRoutes.LOGIN]);
  }

  public getEncodedAccessToken(): EncodedToken | null {
    return sessionStorage.getItem(accessTokenItem);
  }

  public getPermissions(): PermissionType[] {
    const permissions = sessionStorage.getItem(permissionsItem);
    if (!permissions) {
      return [];
    }
    return JSON.parse(permissions);
  }

  public hasPermission(permission: PermissionType): boolean {
    return this.getPermissions().includes(permission);
  }

  public isAllowedToReview(): boolean {
    return this.hasPermission("LEGITIMATION_REVIEW");
  }

  public getUsername(): string {
    const username = sessionStorage.getItem(usernameItem);
    return username != null ? username : "";
  }

  public setSession(authResult: TokenResponse): void {
    const expiresAt: Moment = moment().add(authResult.expires_in, "second");
    sessionStorage.setItem(accessTokenItem, authResult.access_token);
    sessionStorage.setItem(refreshTokenItem, authResult.refresh_token);
    sessionStorage.setItem(expiresAtItem, JSON.stringify(expiresAt.valueOf()));
    sessionStorage.setItem(
      permissionsItem,
      JSON.stringify(AuthService.decodeToken(authResult.access_token).authorities)
    );
    sessionStorage.setItem(
      usernameItem,
      AuthService.decodeToken(authResult.access_token).user_name
    );
    this.loggedIn$.next(true);
  }

  private postForToken(request: URLSearchParams): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(
      environment.api.auth.login,
      request.toString(),
      this.getTokenRequestOptions()
    );
  }

  private getTokenRequestOptions() {
    return {
      headers: {
        Authorization: this.clientAuthorizationHeader,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
  }
}
