import { Component, Input, OnInit } from "@angular/core";
import { default as STRINGS }  from "../../../assets/strings.json";

@Component({
  selector: "app-routing-back",
  templateUrl: "./routing-back.component.html",
  styleUrls: ["./routing-back.component.scss"],
})
export class RoutingBackComponent implements OnInit {
  readonly routingBackLabel: string = STRINGS.routingBackLabel;

  @Input()
  routerLink: string = "..";

  constructor() {}

  ngOnInit(): void {}
}
