export class StringsUtil {
  public static format(formatString: string, ...args: (string | null)[]): string {
    const argumentValues = args.map<string>((x) => (x == null ? "" : x));

    return formatString.replace(/{(\d+)}/g, (match, n) =>
      argumentValues[n] !== undefined ? argumentValues[n] : match
    );
  }

  public static isBlank(str: string | null | undefined): boolean {
    return str == null || str.trim().length === 0;
  }

  public static compareAlphabeticOrder(firstString: string, secondString: string): number {
    if (firstString < secondString) {
      return -1;
    } else if (firstString > secondString) {
      return 1;
    }
    return 0;
  }
}
