import { Component, OnInit } from "@angular/core";
import { HealthcheckService } from "./services/healthcheck.service";
import { default as STRINGS } from "../../../assets/strings.json";
import { SubscribingComponent } from "../../shared/subscribing/subscribing.component";
import { MatDialog } from "@angular/material/dialog";
import { DetailsDialogComponent } from "./details-dialog/details-dialog.component";
import { default as dashboardBuildInfo } from "src/git-version.json";
import { HealthComponent } from "./HealthStatusOfCore";

type TableRow = {
  label: string;
  healthStatus: boolean;
  details?: any;
  statusInfo?: any;
};

@Component({
  selector: "app-healthchecks",
  templateUrl: "./healthchecks.component.html",
  styleUrls: ["./healthchecks.component.scss"]
})
export class HealthchecksComponent extends SubscribingComponent implements OnInit {
  readonly systemControlTitle = STRINGS.systemControlTitle;
  readonly healthcheckTitle = STRINGS.systemControlHealthcheckTitle;
  readonly healthcheckHeaderSystemLabel = STRINGS.systemControlHealthcheckHeaderSystemLabel;
  readonly healthcheckHeaderStatusLabel = STRINGS.systemControlHealthcheckHeaderStatusLabel;
  readonly healthcheckDetailsButtonLabel = STRINGS.systemControlDetailsButtonLabel;

  isLoadingData = false;

  tableRows: { [index: string]: TableRow } = {
    core: {
      label: STRINGS.systemControlCoreSystemTitle,
      healthStatus: false
    },
    database: {
      label: STRINGS.systemControlDatabaseTitle,
      healthStatus: false
    },
    argo: {
      label: STRINGS.systemControlArgoTitle,
      healthStatus: false
    },
    aiWrapper: {
      label: STRINGS.systemControlAiWrapperTitle,
      healthStatus: false
    },
    loki: {
      label: STRINGS.systemControlLoggingTitle,
      healthStatus: false
    },
    dashboard: {
      label: STRINGS.systemControlSelfSystemTitle,
      healthStatus: true,
      details: dashboardBuildInfo.info
    }
  };

  constructor(private readonly healthcheckService: HealthcheckService, public dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.loadHealthCheck();
    this.loadDetails();
  }

  showComponentDetailsDialog(row: any): void {
    this.dialog.open(DetailsDialogComponent, {
      data: {
        componentName: row.label,
        details: {
          statusInfo: row.statusInfo,
          details: row.details
        }
      }
    });
  }

  public getTableRows(): TableRow[] {
    return Object.values(this.tableRows);
  }

  private loadHealthCheck(): void {
    this.isLoadingData = true;
    this.healthcheckService.getWholeCoreHealth().subscribe((coreHealth) => {
      this.tableRows.core.healthStatus = coreHealth.status;
      this.setTableRow("database", coreHealth.database);
      this.setTableRow("aiWrapper", coreHealth.aiWrapper);
      this.setTableRow("loki", coreHealth.loki);
      this.setTableRow("argo", coreHealth.argo);
      this.isLoadingData = false;
    });
  }

  private setTableRow(key: string, healthComponent: HealthComponent) {
    this.tableRows[key].healthStatus = healthComponent.status;
    this.tableRows[key].statusInfo = healthComponent.details;
  }

  private loadDetails(): void {
    this.isLoadingData = true;
    this.subscribe(this.healthcheckService.getCoreHealthInfoRequest(), (res) => {
      this.tableRows.core.details = res;
      this.isLoadingData = false;
    });
  }
}
