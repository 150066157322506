import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ReviewRealtimeService } from "../review/review-realtime.service";
import { Router } from "@angular/router";
import { AuthService } from "../auth/services/auth.service";
import { interval, Observable, Subject, Subscription } from "rxjs";
import { default as STRINGS } from "../../assets/strings.json";
import Push from "push.js";
import { AppRoutes } from "../app.routes";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private timingSubscription!: Subscription;
  private previouslyReadyLegitimations: number = 0;
  private blinkingTitleInterval?: number;
  private destroy: Subject<void> = new Subject<void>();

  private static getTitleNotification(legitimationCount: number): string {
    return "(" + legitimationCount + ") " + STRINGS.appTitle;
  }

  public constructor(
    private readonly authService: AuthService,
    private readonly titleService: Title,
    private readonly reviewRealtimeService: ReviewRealtimeService,
    private readonly router: Router
  ) {}

  public initialiseNotifications(): void {
    this.timingSubscription = interval(20000).subscribe(() => {
      if (this.authService.isAllowedToReview()) {
        this.countLegitimationsReadyForReview().subscribe((numOfLegis) => {
          this.updateTitleAndSendNotification(numOfLegis);
          this.previouslyReadyLegitimations = numOfLegis;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.timingSubscription.unsubscribe();
  }

  public endNotification(): void {
    if (this.timingSubscription) {
      this.timingSubscription.unsubscribe();
    }
    this.clearBlinkingInterval();
    this.titleService.setTitle(STRINGS.appTitle);
    this.destroy.next();
  }

  private updateTitleAndSendNotification(numOfLegis: number): void {
    if (this.previouslyReadyLegitimations < numOfLegis) {
      this.pushMsg(numOfLegis);
      if (!this.blinkingTitleInterval) {
        this.blinkingTitleInterval = setInterval(() => {
          this.toggleTitleNotification(numOfLegis);
        }, 700);
      }
    } else {
      this.clearBlinkingInterval();
    }
  }

  private clearBlinkingInterval() {
    if (this.blinkingTitleInterval) {
      clearInterval(this.blinkingTitleInterval);
      this.blinkingTitleInterval = undefined;
    }
  }

  private countLegitimationsReadyForReview(): Observable<number> {
    return this.reviewRealtimeService.getNumbersOfLegitimations().pipe(
      map((numberOfLegitimations) => {
        return numberOfLegitimations.READY_FOR_REVIEW;
      })
    );
  }

  private toggleTitleNotification(legitimationCount: number): void {
    const notificationTitle = NotificationService.getTitleNotification(legitimationCount);
    this.titleService.setTitle(notificationTitle);
  }

  private pushMsg(legitimationCount: number): void {
    Push.create("New legitimations ready for review!", {
      body: legitimationCount + " legitimations ready for review.",
      icon: "assets/img/qundo-big.svg",
      timeout: 8000,
      silent: true,
      onClick: () => {
        this.router.navigate([AppRoutes.REVIEW]);
      }
    }).catch((e: any) => console.error(e));
  }
}
