import { PermissionType } from "./roles-and-rights/model/Permission";

export enum AppRoutes {
  PARTNER = "partner",
  AUTH = "auth",
  AI_THRESHOLDS = "ai-thresholds",
  LEGITIMATIONS = "legitimations",
  REVIEW = "review",
  OCR_REQUESTS = "ocr-requests",
  SYSTEM_CONTROL = "system-control",
  ROLES_AND_RIGHTS = "roles-and-rights",
  STATISTICS = "statistics",
  SECOND_HUMAN_EYE_STATISTICS = "second-human-eye-statistics",
  INTERNAL = "internal",
  ACCOUNT = "account",
}

export const appRoutePermission: { [k in AppRoutes]?: PermissionType } = {
  [AppRoutes.PARTNER]: "PARTNER_MANAGEMENT",
  [AppRoutes.AI_THRESHOLDS]: "CHECK_CONFIG_MANAGEMENT",
  [AppRoutes.LEGITIMATIONS]: "LEGITIMATION_VIEW_ALL",
  [AppRoutes.OCR_REQUESTS]: "OCR_EVALUATION",
  [AppRoutes.ROLES_AND_RIGHTS]: "USER_MANAGEMENT",
  [AppRoutes.STATISTICS]: "STATISTICS",
  [AppRoutes.SECOND_HUMAN_EYE_STATISTICS]: "STATISTICS",
  [AppRoutes.REVIEW]: "LEGITIMATION_REVIEW",
};
