import { HealthIconComponent } from "./healthchecks/health-icon/health-icon.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HealthchecksComponent } from "./healthchecks/healthchecks.component";
import { Route, RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DetailsDialogComponent } from "./healthchecks/details-dialog/details-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";

const ANGULAR_ROUTES: Route[] = [
  {
    path: "",
    pathMatch: "full",
    component: HealthchecksComponent
  }
];

@NgModule({
  declarations: [HealthchecksComponent, HealthIconComponent, DetailsDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(ANGULAR_ROUTES),
    SharedModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule
  ],
  exports: [HealthIconComponent]
})
export class SystemControlModule {}
