import { environment } from "./../../../../environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { StringsUtil } from "src/app/shared/util/strings.util";

@Injectable({
  providedIn: "root"
})
export class HealthcheckRequestAdapter {
  constructor(private readonly httpClient: HttpClient) {}

  getCoreHealthCheckRequest(): Observable<HttpResponse<Response>> {
    return this.httpClient.get<Response>(StringsUtil.format(environment.api.actuator.health), {
      observe: "response"
    });
  }

  getCoreHealthInfoRequest(): Observable<Response> {
    return this.httpClient.get<Response>(StringsUtil.format(environment.api.actuator.info));
  }
}
